<template>
  <div class="w-100">
    <b-row no-gutters>
      <b-col class="text-stat-line" cols="6">
          {{ $t('views.dashboard.content.stats.ca') }}
      </b-col>
      <b-col class="grey-chart-text text-right mt-1" cols="6">
        {{ analytics.ca.value }} {{ currency }}
      </b-col>
    </b-row>
    <b-row class="mt-3" no-gutters>
      <b-col class="text-stat-line" cols="6">
        {{ $t('views.dashboard.content.stats.evolution') }}
      </b-col>
      <b-col class="grey-chart-text  mt-1 text-right" cols="6">
        {{ analytics.ca.evolution }} {{ currency }}
      </b-col>
    </b-row>
    <b-row class="mt-3" no-gutters>
      <b-col class="text-stat-line" cols="6">
        {{ $t('views.dashboard.content.stats.bookings') }}
      </b-col>
      <b-col class="grey-chart-text mt-2 text-right" cols="6">
        {{ analytics.bookings.value }} {{ currency }}
      </b-col>
    </b-row>
    <b-row class="mt-3" no-gutters>
      <b-col class="text-stat-line" cols="6">
        {{ $t('views.dashboard.content.stats.evolution') }}
      </b-col>
      <b-col class="grey-chart-text mt-1 text-right" cols="6">
        {{ analytics.bookings.evolution }} {{ currency }}
      </b-col>
    </b-row>
  </div>
</template>
<script>
  export default {
    props: {
      analytics: {
        type: Object,
        default: () => ({
          ca: {
            value: 0,
            evolution: 0,
          },
          bookings: {
            value: 0,
            evolution: 0,
          }
        })
      }
    },
    computed: {
      currency() {
        return this.$store.getters['currency/getCurrency'][JSON.parse(localStorage.getItem('current-club')).currency];
      },
    },
  }
</script>
